
import { getAuth } from 'firebase/auth'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA6ZkivbLMcGZ7rqjnkV8f74YOoyIFnFr8",
  authDomain: "communityadwatch.firebaseapp.com",
  projectId: "communityadwatch",
  storageBucket: "communityadwatch.appspot.com",
  messagingSenderId: "722063791008",
  appId: "1:722063791008:web:ee7b2d1fc25d852d8bcac5"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;
