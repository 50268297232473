import { useEffect } from 'react';
import AOS from 'aos';

// routes
import Routes from './routes/Routes';

// helpers
import { configureFakeBackend } from './helpers';

// Themes
import './assets/scss/theme.scss';

// Context
import { UserAuthContextProvider } from './UserAuthContext'; // Adjust the import path if necessary

const App = () => {
    useEffect(() => {
        AOS.init();
        (document.body.style as any).zoom = '75%';
    }, []);

    configureFakeBackend();

    return (
        <UserAuthContextProvider>
            <Routes />
        </UserAuthContextProvider>
    );
};

export default App;
